import PropTypes from 'prop-types';
import React from 'react';
import StyledSystemPropTypes, { propType as StyledSystemPropType } from '@styled-system/prop-types';

import { FlameResponsiveIcon, FLAME_ICON_WIDTH } from '@components/icons/flame-responsive';
import { Box, Flex } from '../grid';
import { H2 } from '../typography';

const HeadingWithFlameHighlights = ({
  heading,
  color,
  backgroundColor,
  borderRadius,
  mt,
  mb,
  headingLevel: Heading,
  lineHeight,
  textAlign,
  justifyContent,
  flameDecalColor,
  ...rest
}) => {
  if (!heading) {
    return null;
  }

  return (
    <Flex
      mt={mt}
      mb={mb}
      px="0.6rem"
      position="relative"
      justifyContent={justifyContent}
      borderRadius={borderRadius || '4px'}
      maxWidth="100%"
      width={{ _: '100%', sm: 'fit-content' }}
      backgroundColor={backgroundColor}
      {...rest}
    >
      <Heading
        textAlign={textAlign}
        color={color}
        maxWidth="fit-content"
        lineHeight={lineHeight}
        pr={
          flameDecalColor
            ? { _: `calc(1.5rem + ${FLAME_ICON_WIDTH._})`, md: `calc(1.5rem + ${FLAME_ICON_WIDTH.md})` }
            : undefined
        }
      >
        {heading}
      </Heading>
      {flameDecalColor && (
        <Box position="absolute" bottom="0.5rem" right="0.5rem">
          <FlameResponsiveIcon fill={flameDecalColor} isResponsive />
        </Box>
      )}
    </Flex>
  );
};

HeadingWithFlameHighlights.propTypes = {
  heading: PropTypes.string.isRequired,
  backgroundColor: StyledSystemPropTypes.color.backgroundColor,
  borderRadius: StyledSystemPropTypes.border.border,
  color: StyledSystemPropTypes.color.color,
  mt: StyledSystemPropType,
  mb: StyledSystemPropType,
  headingLevel: PropTypes.object,
  textAlign: PropTypes.string,
  lineHeight: StyledSystemPropTypes.typography.lineHeight,
  justifyContent: PropTypes.string,
  flameDecalColor: PropTypes.string,
};

HeadingWithFlameHighlights.defaultProps = {
  backgroundColor: null,
  borderRadius: null,
  color: null,
  headingLevel: H2,
  mb: '1.5rem',
  mt: null,
  lineHeight: 1.4,
  textAlign: 'left',
  justifyContent: 'left',
  flameDecalColor: null,
};

export { HeadingWithFlameHighlights };
