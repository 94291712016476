import { DELIVERY_DISCOUNT_CATEGORY_DESCRIPTIONS } from '@nandosaus/constants';

const DeliveryDiscountCategoryDescription = () => {
  return (
    <>
      Purchase any Coke variety online for 1-in-4 chances to win an epic prize!
      <br />
      {DELIVERY_DISCOUNT_CATEGORY_DESCRIPTIONS.COKE}
    </>
  );
};

export { DeliveryDiscountCategoryDescription };
