import { Box } from '../grid';
import { navDefaultProps, navPropTypes } from './nav-prop-types';
import { Pill } from '../pill';

const MobileNav = ({ selectedSectionId, handlePageScroll, navItems, itemRefs, handleItemClick }) => (
  <Box
    display={{ _: 'flex', lg: 'none' }}
    alignItems="center"
    px={{ _: '1.5rem', md: 2 }}
    gap="12px"
    onScroll={handlePageScroll}
  >
    {navItems.map(item => (
      <Pill
        key={item.id}
        isActive={selectedSectionId === item.id}
        initialText={item.label}
        outline={false}
        onClick={() => handleItemClick(item.id)}
        ref={itemRefs[item.id]}
      />
    ))}
  </Box>
);

MobileNav.propTypes = navPropTypes;
MobileNav.defaultProps = navDefaultProps;

export { MobileNav };
