import { isEmpty } from 'lodash';
import { observer } from 'mobx-react';
import { useRef, useState } from 'react';
import { useRootStore } from '@nandosaus/state-management';
import dynamic from 'next/dynamic';
import PropTypes from 'prop-types';

import { Box } from '@components/grid';
import { ANALYTICS_EVENTS } from '@nandosaus/constants';
import { analytics } from '../../analytics';
import { Icon } from '../icons';
import { Pill } from '../pill';
import { useIsMinWidth } from '../../hooks/use-is-min-width';

const Filter = observer(({ children, onApply, pillText, initialFormState }) => {
  const [isFilterPanelOpen, setIsFilterPanelOpen] = useState(false);
  const pillRef = useRef(null);
  const isMobile = !useIsMinWidth('lg');

  const { DietaryPreferencesStore } = useRootStore();
  const { dietaryPreferencesList } = DietaryPreferencesStore;

  const handleClose = () => setIsFilterPanelOpen(false);

  const handleSaveAndApply = value => {
    onApply(value);
    handleClose();
  };

  const FilterPanelComponent = dynamic(
    () =>
      isMobile
        ? import('./filter-panel-mobile').then(mod => mod.FilterPanelMobile)
        : import('./filter-panel-desktop-and-tablet').then(mod => mod.FilterPanelDesktopAndTablet),
    { ssr: false }
  );

  return (
    <>
      <Pill
        isButtonStyled
        choices={dietaryPreferencesList}
        initialText={pillText}
        onClick={() => {
          setIsFilterPanelOpen(!isFilterPanelOpen);

          analytics.track(ANALYTICS_EVENTS.DIETARY_PREFERENCES_OPENED);
        }}
        ref={pillRef}
        isActive={isFilterPanelOpen}
        rightIcon={
          isEmpty(dietaryPreferencesList) ? (
            <Icon name="filter" ml="0.5rem" fill="currentColor" height="20px" width="20px" />
          ) : (
            <Box height="20px" />
          )
        }
      />
      {isFilterPanelOpen && (
        <FilterPanelComponent
          pillRef={pillRef}
          isVisible={isFilterPanelOpen}
          onClose={handleClose}
          onSaveAndApply={handleSaveAndApply}
          initialFormState={initialFormState}
        >
          {children}
        </FilterPanelComponent>
      )}
    </>
  );
});

Filter.propTypes = {
  children: PropTypes.node.isRequired,
  initialFormState: PropTypes.shape({}).isRequired,
  onApply: PropTypes.func.isRequired,
  pillAnalytics: PropTypes.shape({}),
  pillText: PropTypes.string.isRequired,
};

Filter.defaultProps = {
  pillAnalytics: undefined,
};

export { Filter };
