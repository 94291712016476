import { space as styledSystemSpace } from 'styled-system';
import styled from 'styled-components';

import { breakpoints, colors } from '../../constants';
import { Flex } from '../grid';
import { pStyles } from '../typography/body';

const Nav = styled(Flex)`
  @media (min-width: ${breakpoints.lg}) {
    width: 100%;
  }
`;

const NavLink = styled.button`
  ${pStyles(3)};
  background-color: transparent;
  border: none;
  color: ${colors.greyPrimary};
  height: 100%;
  line-height: 1;
  outline: none;
  padding: 0;
  text-decoration: none;
  transition: ease 0.1s;
  white-space: nowrap;
`;

const NavItem = styled.div`
  ${styledSystemSpace}
  align-items: center;
  border-bottom: 2px solid transparent;
  display: flex;
  transition: border-bottom 0.2s ease;
  user-select: none;

  ${({ active }) =>
    active &&
    `
      border-color: ${colors.neonTeal400};

      ${NavLink} {
        color: ${colors.grey800};
        outline: none;
      }
  `}

  &:hover {
    ${NavLink} {
      color: ${colors.grey800};
    }
  }
`;

export { Nav, NavLink, NavItem };
