import { get } from 'lodash';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Box, Flex } from '../../grid';
import { colors } from '../../../constants';
import { Icon } from '../../icons';
import { P } from '../../typography';

const FilterCheckbox = ({ name, label, subtitle, optionValues, iconName, onChecked }) => {
  const [field, meta, helpers] = useField({ name, type: 'checkbox' });

  const setChecked = checked => {
    if (checked) {
      helpers.setValue(get(optionValues, 'on', checked));
      onChecked(name);
    } else {
      helpers.setValue(get(optionValues, 'off', checked));
    }
  };

  const { value } = meta;
  const checked = value !== optionValues.off;

  return (
    <Clickable
      mb={{ xs: 1, md: '0.5rem' }}
      as="label"
      htmlFor={name}
      alignItems="center"
      justifyContent="flex-start"
      flex={1}
      border={checked ? `2px solid ${colors.success600}` : `1px solid ${colors.grey100}`}
      borderRadius="4px"
    >
      {iconName && (
        <Box as="span" display="block" p="24px">
          <Icon name={iconName} height="32px" width="32px" />
        </Box>
      )}
      <Flex height="75px" alignItems="center" py={checked ? '0.5rem' : 0}>
        <Box flex={5} as="span" display="block">
          {label && (
            <P as="span" display="block" variant={2} pr={1} fontWeight="bold">
              {label}
            </P>
          )}
          {checked && subtitle && (
            <P as="span" display="block" mt="0.2rem" variant={4} pr={1}>
              {subtitle}
            </P>
          )}
        </Box>
        <HiddenCheckboxInput
          {...field}
          id={name}
          type="checkbox"
          checked={checked}
          onChange={event => setChecked(event.target.checked)}
        />
      </Flex>
    </Clickable>
  );
};

const HiddenCheckboxInput = styled.input.attrs({ type: 'checkbox' })`
  /* Hide checkbox visually but remain accessible to screen readers. */
  position: absolute;
  opacity: 0;
  z-index: -1;
`;

const Clickable = styled(Flex)`
  cursor: pointer;
`;

FilterCheckbox.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  optionValues: PropTypes.shape({ on: PropTypes.string, off: PropTypes.string }).isRequired,
  subtitle: PropTypes.string,
  iconName: PropTypes.string.isRequired,
  onChecked: PropTypes.func.isRequired,
};

FilterCheckbox.defaultProps = {
  label: null,
  subtitle: null,
};

export { FilterCheckbox };
