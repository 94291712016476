import { motion } from 'framer-motion';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useRootStore } from '@nandosaus/state-management';
import styled from 'styled-components';

import { Box } from '../../grid';
import { P } from '../../typography';
import { usePrevious } from '../../../hooks/use-previous';

const AddedToOrderToast = () => {
  const { CartStore, PerksShopStore } = useRootStore();
  const [isToastVisible, setIsToastVisible] = useState(false);

  const { itemCount } = CartStore;
  const { cartItemsCount: perksStoreItemsCount } = PerksShopStore;
  const previousItemCount = usePrevious(itemCount);
  const previousPerksStoreItemCount = usePrevious(perksStoreItemsCount);

  useEffect(() => {
    if (itemCount > previousItemCount || perksStoreItemsCount > previousPerksStoreItemCount) {
      setIsToastVisible(true);
    }

    const timer = setTimeout(() => {
      setIsToastVisible(false);
    }, 1600);

    return () => clearTimeout(timer);
  }, [itemCount, previousItemCount, perksStoreItemsCount, previousPerksStoreItemCount]);

  return (
    <motion.div
      initial={isToastVisible}
      animate={isToastVisible ? 'visible' : 'hidden'}
      variants={{
        visible: { opacity: 1, height: 'auto' },
        hidden: { opacity: 0 },
      }}
      transition={{ delay: 0.2, duration: 0.3, ease: 'easeOut' }}
      aria-hidden={!isToastVisible}
    >
      <Wrapper py="0.5rem" px={1} backgroundColor="white">
        <P variant={3} color="pink700">
          Added to your order
        </P>
      </Wrapper>
    </motion.div>
  );
};

const Wrapper = styled(Box)`
  position: absolute;
  top: calc(100% + 6px);
  right: 0;
  white-space: nowrap;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
`;

const ObservedAddedToOrderToast = observer(AddedToOrderToast);
export { ObservedAddedToOrderToast as AddedToOrderToast };
