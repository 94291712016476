import { find } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { Icon } from '../icons';
import { navDefaultProps } from './nav-prop-types';
import { NavItem } from './styles';
import { Pill } from '../pill';

const MoreSelect = ({ selectRef, selectedSectionId, navItems, children, isOpen, onClick }) => {
  if (!children) return null;

  return (
    <Select>
      <Pill
        initialText="More"
        key="more"
        isActive={!!find(navItems, navItem => navItem.id === selectedSectionId)}
        onClick={() => onClick()}
        rightIcon={<StyledIcon name="chevron" fill="currentColor" aria-hidden="true" />}
        outline={false}
      />
      <SelectItems ref={selectRef} isOpen={isOpen}>
        {children}
      </SelectItems>
    </Select>
  );
};

const SelectItems = styled.div`
  align-items: flex-start;
  background-color: white;
  border-radius: 2px;
  box-shadow: 0px 10px 30px 10px rgba(43, 43, 43, 0.3);
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  flex-direction: column;
  padding: 0.25rem 0 1rem;
  position: absolute;
  right: 0;
  top: 2.5rem;
  z-index: 30;
`;
const StyledIcon = styled(Icon)`
  margin-left: 0.25rem;
  height: 10px;
  width: 10px;
  transform: scale(2.4);
`;

const Select = styled.div`
  display: flex;
  position: relative;

  ${SelectItems} {
    ${NavItem}:first-child {
      margin-left: 1rem;
    }
  }
`;

MoreSelect.propTypes = {
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  navItems: PropTypes.array.isRequired,
  onClick: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  selectRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })]).isRequired,
  selectedSectionId: PropTypes.string,
};

MoreSelect.defaultProps = navDefaultProps;

export { MoreSelect };
