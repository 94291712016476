import PropTypes from 'prop-types';

const navPropTypes = {
  handleItemClick: PropTypes.func.isRequired,
  handlePageScroll: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  itemRefs: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })]))
    .isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  navItems: PropTypes.array.isRequired,
  selectedSectionId: PropTypes.string,
};

const navDefaultProps = {
  selectedSectionId: null,
};

export { navPropTypes, navDefaultProps };
