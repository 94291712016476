import { observer } from 'mobx-react';
import { useRootStore } from '@nandosaus/state-management';
import React from 'react';

import { ANALYTICS_EVENTS } from '@nandosaus/constants';
import { analytics } from '../../analytics';
import { Box } from '../grid';
import { Filter } from '../filter';
import { FilterList } from './filter-list';
import { Link } from '../link';
import { P, Subheading } from '../typography';
import { useIsMinWidth } from '../../hooks/use-is-min-width';

const DietaryFilter = () => {
  const { MenuStore, DietaryPreferencesStore } = useRootStore();
  const isMobile = !useIsMinWidth('lg');

  const { allergenLink } = MenuStore;

  return (
    <Filter
      onApply={value => {
        DietaryPreferencesStore.saveDietaryPreferences(value);
        window.scrollTo(0, 0);
        analytics.track(ANALYTICS_EVENTS.DIETARY_PREFERENCES_UPDATED, {
          dietaryPreferences: DietaryPreferencesStore.dietaryPreferencesList,
        });
      }}
      pillText="Dietary Preferences"
      initialFormState={DietaryPreferencesStore}
    >
      <>
        <Box textAlign="center" mb={{ _: 2, lg: 1 }}>
          <Subheading variant={3} mb={1}>
            Dietary preferences
          </Subheading>
          <P variant={isMobile ? 2 : 4}>
            Setting these will hide things you can’t eat from the menu. Some items will have Veg options but need
            customisation.
          </P>
        </Box>
        <FilterList />
        <P variant={4} mt={{ xs: '0.5rem', md: 1 }} textAlign="center" color="grey700">
          These filters should be only used for dietary preferences, not dietary requirements due to allergens. We can’t
          guarantee our products are free from allergens as there’s always a risk of allergen traces due to
          cross-contact. If you have a food allergy, please tell us when ordering and read our full allergen information
          {allergenLink && (
            <Link
              href={allergenLink.url}
              target="_blank"
              variant={4}
              onClick={() => analytics.track(ANALYTICS_EVENTS.ALLERGEN_DOCUMENT_VIEWED)}
            >
              {' '}
              here
            </Link>
          )}
          .
        </P>
      </>
    </Filter>
  );
};

const ObservedDietaryFilter = observer(DietaryFilter);
export { ObservedDietaryFilter as DietaryFilter };
