import { isEmpty } from 'lodash';
import { observer } from 'mobx-react';
import { productsArrayPropTypes } from '@nandosaus/prop-types';
import PropTypes from 'prop-types';

import { MENU_CATEGORY_COLOURS, MENU_CATEGORY_FLAME_COLOURS } from '@nandosaus/constants';
import { HeadingWithFlameHighlights } from '@components/heading-with-flame-highlights';
import { useBrazeImpression } from '@hooks/use-braze-impression';
import { CardListing } from '../../card-listing';
import { H2, P } from '../../typography';
import { perksShopProductArrayPropTypes } from '../../../prop-types';
import { useFeature } from '../../../hooks/use-feature';

const ProductCategory = ({ category, viewProduct, CardComponent }) => {
  const showCategoryDescription = useFeature('menu-category-descriptions');
  const hasDescription = !isEmpty(category.description);
  const { brazeLogImpression } = category;

  useBrazeImpression({ brazeLogImpression });

  return (
    <>
      <HeadingWithFlameHighlights
        backgroundColor={MENU_CATEGORY_COLOURS[category.handle] ?? MENU_CATEGORY_COLOURS.default}
        color="white"
        headingLevel={H2}
        heading={category.name}
        flameDecalColor={MENU_CATEGORY_FLAME_COLOURS[category.handle] ?? MENU_CATEGORY_FLAME_COLOURS.default}
        mt={{ _: '5rem', md: '2rem', lg: '1rem' }}
        mb="2rem"
      />
      {showCategoryDescription && hasDescription && (
        <P variant={2} mb={{ _: '1.5rem', lg: '2rem' }} width={{ xl: 9 / 12 }}>
          {category.description}
        </P>
      )}
      <CardListing
        shouldKeepVertical
        CardComponent={CardComponent}
        cardWidth={{ xs: 6 / 12, sm: 4 / 12, xxl: 3 / 12 }}
        categoryName={category.name}
        items={category.products}
        onClick={item => {
          viewProduct(item);
          category?.brazeLogClick?.();
        }}
        cardBackgroundColor="transparent"
      />
    </>
  );
};

ProductCategory.propTypes = {
  CardComponent: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  category: PropTypes.shape({
    name: PropTypes.string.isRequired,
    handle: PropTypes.string.isRequired,
    description: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    products: PropTypes.oneOfType([productsArrayPropTypes.isRequired, perksShopProductArrayPropTypes.isRequired]),
    brazeLogImpression: PropTypes.func,
    brazeLogClick: PropTypes.func,
  }).isRequired,
  viewProduct: PropTypes.func.isRequired,
};

const ComposedProductCategory = observer(ProductCategory);
export { ComposedProductCategory as ProductCategory };
