import { forwardRef } from 'react';
import { isEmpty, size } from 'lodash';
import { observer } from 'mobx-react';

import PropTypes from 'prop-types';
import { PillText, StyledButtonPill, StyledPill } from './styles';

const getTitleToRender = ({ choices, initialText, overflowText }) => {
  if (isEmpty(choices)) {
    return initialText;
  }

  return `${overflowText || initialText} (${size(choices)})`;
};

const getVariant = ({ hasChoices, isActive, outline }) => {
  if (hasChoices || isActive) {
    return 'active';
  }
  if (!outline) {
    return 'plain';
  }
  return 'default';
};

const Pill = observer(
  ({
    choices,
    initialText,
    onClick,
    overflowText,
    isActive,
    isButtonStyled,
    forwardedRef,
    outline,
    leftIcon,
    rightIcon,
  }) => {
    const hasChoices = !isEmpty(choices);
    const variant = getVariant({ hasChoices, isActive, outline });
    const title = getTitleToRender({ choices, initialText, overflowText });
    const PillComponent = isButtonStyled ? StyledButtonPill : StyledPill;
    return (
      <PillComponent onClick={onClick} variant={variant} ref={forwardedRef}>
        {leftIcon && leftIcon}
        <PillText variant={3}>{title}</PillText>
        {rightIcon && rightIcon}
      </PillComponent>
    );
  }
);

Pill.propTypes = {
  choices: PropTypes.arrayOf(PropTypes.string),
  variant: PropTypes.oneOf(['default', 'active', 'plain', 'primary']),
  initialText: PropTypes.string.isRequired,
  isActive: PropTypes.bool,
  isButtonStyled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  overflowText: PropTypes.string,
  outline: PropTypes.bool,
  leftIcon: PropTypes.node,
  rightIcon: PropTypes.node,
};

Pill.defaultProps = {
  choices: [],
  variant: undefined,
  isActive: false,
  isButtonStyled: false,
  overflowText: '',
  outline: true,
  leftIcon: undefined,
  rightIcon: undefined,
};

const ComposedPill = forwardRef((props, ref) => <Pill {...props} forwardedRef={ref} />);

export { ComposedPill as Pill };
