import { camelCase, get, map } from 'lodash';
import { DIETARY_PREFERENCES_OPTIONS, DIETARY_STATUS, DIETARY_TYPES } from '@nandosaus/constants';
import { useFormikContext } from 'formik';
import React from 'react';

import { FilterCheckbox } from '../filter-checkbox';

const FilterList = () => {
  const { values, setFieldValue } = useFormikContext();

  const onChecked = checkboxName => {
    const vegan = camelCase(DIETARY_TYPES.VEGAN);
    const vegetarian = camelCase(DIETARY_TYPES.VEGETARIAN);

    if (checkboxName === vegan && get(values, vegetarian) === DIETARY_STATUS.YES) {
      setFieldValue(vegetarian, DIETARY_STATUS.NO);
    }

    if (checkboxName === vegetarian && get(values, vegan) === DIETARY_STATUS.YES) {
      setFieldValue(vegan, DIETARY_STATUS.NO);
    }
  };

  return map(DIETARY_PREFERENCES_OPTIONS, ({ label, description, iconName }, key) => {
    const checkboxName = camelCase(key);

    return (
      <React.Fragment key={checkboxName}>
        <FilterCheckbox
          label={label}
          name={checkboxName}
          iconName={iconName}
          subtitle={description}
          optionValues={{ on: DIETARY_STATUS.YES, off: DIETARY_STATUS.NO }}
          onChecked={onChecked}
        />
      </React.Fragment>
    );
  });
};

export { FilterList };
