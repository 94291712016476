import { get, invoke, isNil, isNull, max, min, set } from 'lodash';

const getElementVisibleHeight = id => {
  if (isNil(document)) {
    return null;
  }

  const element = document.getElementById(id);

  if (isNil(element)) {
    return null;
  }

  const clientRect = element.getBoundingClientRect();
  let visibleHeight = 0;

  if (clientRect.top > 0) {
    // Top of the element is below the top of the viewport
    if (clientRect.top + clientRect.height < window.innerHeight) {
      // The element is entirely visible within the viewport
      visibleHeight = clientRect.height;
    } else if (clientRect.top < window.innerHeight) {
      // The element is partially visible at the bottom of the viewport
      visibleHeight = window.innerHeight - clientRect.top;
    }
  } else if (clientRect.top + clientRect.height > window.innerHeight) {
    // The element entirely consumes the viewport
    visibleHeight = window.innerHeight;
  } else if (clientRect.top + clientRect.height > 0) {
    // The element is partially visible at the top of the viewport
    visibleHeight = clientRect.top + clientRect.height;
  }

  return visibleHeight;
};

const centerElementInScrollContainer = (container, element) => {
  const maxContainerScrollLeft = container.scrollWidth - container.offsetWidth;
  const viewportCenter = Math.round(container.offsetWidth / 2);
  const elementCenter = Math.round(element.offsetWidth / 2) + element.offsetLeft;

  let containerScrollLeft = min([max([elementCenter - viewportCenter, 0]), maxContainerScrollLeft]);

  if (isNull(element.nextSibling)) {
    containerScrollLeft = maxContainerScrollLeft;
  } else if (isNull(element.previousSibling)) {
    containerScrollLeft = 0;
  }

  set(container, 'scrollLeft', containerScrollLeft);
};

const giveSectionFocus = sectionId => {
  const sectionElement = invoke(document, 'getElementById', sectionId);

  const scrollTopBefore = get(document, 'body.scrollTop');

  set(sectionElement, 'tabIndex', 0);
  invoke(sectionElement, 'focus', {
    preventScroll: true,
  });
  invoke(sectionElement, 'removeAttribute', 'tabIndex');

  // Safari likes to jump when the section gets selected, counter this.
  set(document, 'body.scrollTop', scrollTopBefore);
};

export { getElementVisibleHeight, centerElementInScrollContainer, giveSectionFocus };
