import styled, { css } from 'styled-components';
import { colors } from '@nandosaus/constants';
import { variant as styledVariant } from 'styled-system';
import { P } from '../typography';

const greenHoverState = {
  backgroundColor: colors.neonTeal100,
  color: colors.neonTeal800,
  border: `1px solid ${colors.neonTeal100}`,
};

export const pillVariants = styledVariant({
  variants: {
    default: {
      backgroundColor: colors.grey50,
      border: `1px solid ${colors.grey300}`,
      '&:hover': greenHoverState,
    },
    active: {
      backgroundColor: colors.black,
      border: `1px solid ${colors.black}`,
      color: colors.white,
    },
    plain: {
      backgroundColor: colors.grey50,
      border: `1px solid ${colors.grey50}`,
      '&:hover': greenHoverState,
    },
  },
});

export const buttonVariants = styledVariant({
  variants: {
    default: {
      backgroundColor: colors.white,
      border: `1px solid ${colors.grey700}`,
      '&:hover': {
        border: `1px solid ${colors.neonTeal400}`,
      },
    },
    active: {
      backgroundColor: colors.black,
      border: `1px solid ${colors.black}`,
      color: colors.white,
    },
    plain: {
      border: `1px solid ${colors.white}`,
      backgroundColor: colors.white,
      '&:hover': greenHoverState,
    },
  },
});

const commonBaseStyles = css`
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${colors.grey700};
`;

export const StyledButtonPill = styled.button`
  border-radius: 4px;

  padding: 3px 12px;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    padding: 5px 14px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    padding: 8px 16px;
  }

  ${commonBaseStyles}

  ${buttonVariants};
`;

export const StyledPill = styled.button`
  border-radius: 30px;

  padding: 5px 12px;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    padding: 7px 14px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    padding: 10px 16px;
  }

  ${commonBaseStyles}

  ${pillVariants};
`;

export const PillText = styled(P)`
  white-space: nowrap;
  line-height: 1;
`;
