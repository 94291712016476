import { useEffect, useRef } from 'react';

/**
 * usePrevious
 *
 * Retrieve previous state/props within a functional component
 *
 * @param {*} value The previous value
 * @see:  https://reactjs.org/docs/hooks-faq.html#how-to-get-the-previous-props-or-state
 * @see:  https://usehooks.com/usePrevious
 */

const usePrevious = value => {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef();

  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes

  // Return previous value (happens before update in useEffect above)
  return ref.current;
};

export { usePrevious };
