import { MARKET, SURCHARGE_TYPE } from '@nandosaus/constants';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Box, Flex } from '../grid';
import { Label, P, Subheading } from '../typography';
import { pricesPropTypes } from '../../prop-types';
import { useMarket } from '../../hooks/use-market';

const OrderTotals = ({
  deliveryCost,
  subtotalPrices,
  discountPrices,
  totalPrices,
  showDisclaimer,
  isDiscountApplied,
  deliverySponsor,
  surcharges,
  smallTotal,
}) => {
  const market = useMarket();
  const holidaySurcharge = surcharges.find(surcharge => surcharge.type === SURCHARGE_TYPE.HOLIDAY_SURCHARGE);
  const hasHolidaySurcharge = !!holidaySurcharge;
  const showNutritionDisclaimer = market === MARKET.AUSTRALIA && showDisclaimer;

  return (
    <Box display="grid" rowGap="0.5rem" py={1}>
      <LineItem>
        <P variant={4} color="greyPrimary">
          Subtotal
        </P>
        <P variant={4} color="greyPrimary">
          {subtotalPrices.formattedPrice}
        </P>
      </LineItem>
      {surcharges.map(surcharge => {
        return (
          <LineItem key={surcharge.type}>
            <P variant={4} color="greyPrimary">
              {surcharge.description}
            </P>
            <P variant={4} color="greyPrimary">
              {surcharge.formattedAmount}
            </P>
          </LineItem>
        );
      })}
      {!!deliveryCost && (
        <LineItem mt="0.75rem">
          <P variant={4} color="greyPrimary">
            Delivery
          </P>
          {isDiscountApplied ? (
            <Flex bg="red500" borderRadius="2px">
              <Label px="0.5rem" py="0.25rem" variant={2} fontWeight="bold" color="white">
                Free{deliverySponsor ? ` with ${deliverySponsor.name}` : ''}
              </Label>
            </Flex>
          ) : (
            <P variant={4} color="greyPrimary">
              {deliveryCost}
            </P>
          )}
        </LineItem>
      )}
      {discountPrices.cents !== 0 && (
        <LineItem>
          <P variant={4} color="orange500">
            Discount
          </P>
          <P variant={4} color="orange500">
            {discountPrices.formattedPrice}
          </P>
        </LineItem>
      )}
      {totalPrices && smallTotal && (
        <LineItem>
          <P variant={3}>Total</P>
          <P variant={3}>{totalPrices.formattedPrice}</P>
        </LineItem>
      )}
      {totalPrices && !smallTotal && (
        <LineItem>
          <Subheading variant={4}>Total</Subheading>
          <Subheading variant={4}>{totalPrices.formattedPrice}</Subheading>
        </LineItem>
      )}
      {(hasHolidaySurcharge || showNutritionDisclaimer) && (
        <Box display="grid" pt="1rem" gap="0.5rem">
          {hasHolidaySurcharge && (
            <P variant={4} color="greyPrimary" textAlign="center">
              A surcharge of {holidaySurcharge.formattedSurcharge} applies on public holidays.
            </P>
          )}
          {showNutritionDisclaimer && (
            <P variant={4} color="greyPrimary" textAlign="center">
              Kilojoule calculations do not include drinks or customisations to menu items. The average adult daily
              energy intake is 8,700 kJ.
            </P>
          )}
        </Box>
      )}
    </Box>
  );
};

const LineItem = styled(Flex)`
  justify-content: space-between;
`;

OrderTotals.propTypes = {
  deliveryCost: PropTypes.string,
  discountPrices: pricesPropTypes,
  subtotalPrices: PropTypes.oneOfType([
    pricesPropTypes,
    PropTypes.shape({
      formattedPrices: PropTypes.string,
    }),
  ]).isRequired,
  totalPrices: pricesPropTypes,
  showDisclaimer: PropTypes.bool,
  surcharges: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      name: PropTypes.string,
      description: PropTypes.string,
      formattedAmount: PropTypes.string,
      formattedSurcharge: PropTypes.string,
    })
  ),

  isDiscountApplied: PropTypes.bool,
  deliverySponsor: PropTypes.shape({
    name: PropTypes.string,
  }),
  smallTotal: PropTypes.bool,
};

OrderTotals.defaultProps = {
  discountPrices: { points: 0, cents: 0 },
  deliveryCost: null,
  showDisclaimer: true,
  totalPrices: null,
  surcharges: [],
  isDiscountApplied: false,
  deliverySponsor: null,
  smallTotal: false,
};

export { OrderTotals };
