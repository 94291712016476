import { ALCOHOL_DISCLAIMERS_BY_STATE, ANALYTICS_EVENTS } from '@nandosaus/constants';
import { observer } from 'mobx-react';
import { productsArrayPropTypes } from '@nandosaus/prop-types';
import { useRootStore } from '@nandosaus/state-management';
import { useState } from 'react';
import PropTypes from 'prop-types';

import { analytics } from '../../../analytics';
import { Box, Col, Flex, Row } from '../../grid';
import { Button } from '../../button';
import { Checkbox } from '../../input';
import { H2, H3, P } from '../../typography';
import { Modal } from '../../modal';
import { perksShopProductArrayPropTypes } from '../../../prop-types';
import { ProductCategory } from '../product-category';
import { useCookie } from '../../../hooks/use-cookie';

const AlcoholProductCategory = ({ category, ...props }) => {
  const { OrderingContextStore } = useRootStore();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [hasVerifiedAge, setHasVerifiedAge] = useCookie('age-verified');
  const disclaimer =
    ALCOHOL_DISCLAIMERS_BY_STATE[OrderingContextStore?.restaurant?.address?.state] || ALCOHOL_DISCLAIMERS_BY_STATE.VIC;

  if (!hasVerifiedAge) {
    return (
      <>
        <Row mb={4} justifyContent="center">
          <Col width={{ xs: 12 / 12, xxl: 10 / 12 }}>
            <Box py={2} px={2}>
              <Flex justifyContent="center" mb={{ _: '0.5rem', md: 1 }}>
                <H2>{category.name}</H2>
              </Flex>
              <Row justifyContent="center">
                <Col width={{ _: 1, md: 8 / 12, lg: 8 / 12 }} justifyContent="center">
                  <P variant={3} textAlign="center">
                    {disclaimer}
                  </P>
                  <Box mt={{ _: 1, md: 2 }} display="flex" justifyContent="center">
                    <Button onClick={() => setModalIsOpen(true)}>Verify Age</Button>
                  </Box>
                </Col>
              </Row>
            </Box>
          </Col>
        </Row>
        <Modal isDividerHidden open={modalIsOpen} handleClose={() => setModalIsOpen(false)}>
          <Box display="flex" justifyContent="center" pb={3}>
            <Box maxWidth="350px" textAlign="center">
              <P mb={2}>You must be of legal age to view & purchase alcohol products.</P>
              <H3 mb={2}>Are you over 18?</H3>
              <Box display="flex" flexGrow={1} gap="0.5rem" mb={2}>
                <Button isFullWidth variant="secondary" onClick={() => setModalIsOpen(false)}>
                  No
                </Button>
                <Button
                  isFullWidth
                  onClick={() => {
                    // Set cookie for 90 days if 'remember me' checked or 1 day if not as Chrome
                    // sessions cookies rarely expire, even after a restart
                    // @see: https://github.com/js-cookie/js-cookie/wiki/Frequently-Asked-Questions#why-does-the-browser-not-remove-the-cookies-after-i-close-it
                    setHasVerifiedAge(true, { expires: rememberMe ? 90 : 1 });
                    setModalIsOpen(false);

                    analytics.track(ANALYTICS_EVENTS.USER_ALCOHOL_AGE_VERIFIED);
                  }}
                >
                  Yes
                </Button>
              </Box>
              <Checkbox
                name="rememberMe"
                label={<P variant={4}>Remember me for next time</P>}
                checked={rememberMe}
                onChange={event => setRememberMe(!!event.target.checked)}
              />
            </Box>
          </Box>
        </Modal>
      </>
    );
  }

  return (
    <ProductCategory
      category={{
        ...category,
        description: disclaimer,
      }}
      {...props}
    />
  );
};

AlcoholProductCategory.propTypes = {
  CardComponent: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  category: PropTypes.shape({
    name: PropTypes.string.isRequired,
    handle: PropTypes.string.isRequired,
    description: PropTypes.string,
    products: PropTypes.oneOfType([productsArrayPropTypes.isRequired, perksShopProductArrayPropTypes.isRequired]),
  }).isRequired,
  viewProduct: PropTypes.func.isRequired,
};

const ComposedAlcoholProductCategory = observer(AlcoholProductCategory);
export { ComposedAlcoholProductCategory as AlcoholProductCategory };
