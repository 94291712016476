import { filter } from 'lodash';
import { MENU_CATEGORIES } from '@nandosaus/constants';
import { observer } from 'mobx-react';
import { productsArrayPropTypes } from '@nandosaus/prop-types';
import PropTypes from 'prop-types';

import { AlcoholProductCategory } from './alcohol-product-category';
import { Container } from '../grid';
import { createNavId } from '../../utils/createNavIds';
import { DeliveryDiscountCategoryDescription } from './delivery-discount-category-description';
import { perksShopProductArrayPropTypes } from '../../prop-types';
import { ProductCategory } from './product-category';

const categoryMap = {
  [MENU_CATEGORIES.ALCOHOL]: AlcoholProductCategory,
};

const ProductCategoryList = ({ CardComponent, categories, viewProduct, isDelivery, isDeliveryDiscountActive }) => {
  const dietaryPreferenceCategory = filter(categories, category => category.products.length !== 0);

  return (
    <>
      {dietaryPreferenceCategory.map(category => {
        const id = createNavId(category.name);
        const CategoryComponent = categoryMap[category.handle] || ProductCategory;
        const showDiscountDescription =
          category.handle === MENU_CATEGORIES.SPIN_TO_WIN && isDelivery && isDeliveryDiscountActive;

        return (
          <Container key={id} mt={{ _: 0, sm: '2rem', lg: '3.5rem' }} mb={{ _: '1.5rem', lg: '2.5rem' }} id={id}>
            <CategoryComponent
              {...{
                category: {
                  ...category,
                  description: showDiscountDescription ? <DeliveryDiscountCategoryDescription /> : category.description,
                },
                CardComponent,
                viewProduct,
              }}
            />
          </Container>
        );
      })}
    </>
  );
};

ProductCategoryList.propTypes = {
  CardComponent: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      handle: PropTypes.string.isRequired,
      description: PropTypes.string,
      products: PropTypes.oneOfType([productsArrayPropTypes.isRequired, perksShopProductArrayPropTypes.isRequired]),
    })
  ).isRequired,
  viewProduct: PropTypes.func.isRequired,
  isDelivery: PropTypes.bool.isRequired,
  isDeliveryDiscountActive: PropTypes.bool,
};

ProductCategoryList.defaultProps = {
  isDeliveryDiscountActive: false,
};

const ComposedProductCategoryList = observer(ProductCategoryList);
export { ComposedProductCategoryList as ProductCategoryList };
